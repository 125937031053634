import React from 'react';

const CaseStudiesEdtechAi = () => {
  return (
    <>
      <div id='site-content' className='edtech-ai'>
        <div id='section-1' className='hero-section'>
          <div className='block-text'>
            <h1>
              <span>EdTech</span> AI
            </h1>
            <span className='s-divider'></span>
            <p>An e-learning platform with AI-driven enhancements.</p>
          </div>
          <img
            src='/caseStudies/newCaseStudies/edtech-ai-hero.jpg'
            className='hero-image'
          />
        </div>

        <div id='section-2'>
          <div className='container'>
            <h2>Introduction</h2>
            <p>
              EdTech AI is an advanced web application that uses AI to enhance
              school management by offering an all-in-one platform for
              curriculum creation, student-teacher interactions, and performance
              monitoring. With the help of AI-powered tools, teachers can easily
              manage their classes, monitor student progress, and communicate
              with their students. Students can take advantage of AI tools to
              access video lessons, receive lectures in text format, participate
              in tests, and earn points for their achievements, making their
              learning experience more engaging. Super Admins have full control
              over the system, enabling them to manage curriculum development,
              oversee data, and provide AI-driven prompts. Devtrust worked on
              building the application, seamlessly integrating AI APIs to create
              a smarter, more personalized e-learning experience for users.
            </p>
          </div>
        </div>

        <div id='section-3'>
          <div className='container imagebox'>
            <div className='text-block'>
              <img src='/caseStudies/newCaseStudies/edtech-icon.svg' />
              <h2>
                <span>Challenges</span>
                <br /> at a glance
              </h2>
              <p>
                Here are the challenges we encountered during the
                application&apos;s development phase
              </p>
            </div>
            <img
              src='/caseStudies/newCaseStudies/edtech-sec3-cover.png'
              className='right-img'
            />
          </div>
          <div className='container textbox-column col-4'>
            <div className='textbox'>
              <span className='marker'>1</span>
              <h3>Disjointed School Management Processes</h3>
              <p>
                Prior to EdTech AI, managing school curriculum, student
                registration, and teacher assignments involved multiple
                disconnected systems, leading to inefficiencies and
                administrative overhead.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>2</span>
              <h3>Lack of Integrated Communication Channels</h3>
              <p>
                Teachers and students struggled with ineffective communication
                channels, impacting the ability to share information, provide
                feedback, and address urgent topics promptly.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>3</span>
              <h3>Inadequate Progress Tracking and Performance Metrics</h3>
              <p>
                There was no centralized system to monitor student progress,
                track performance, and display a comprehensive view of academic
                achievements, which hindered effective progress evaluation.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>4</span>
              <h3>Limited Learning Integration and Engagement</h3>
              <p>
                Existing systems lacked advanced features such as AI-driven
                content, assessments, and interactive learning tools, reducing
                overall engagement and effectiveness of the educational
                experience.
              </p>
            </div>
          </div>
        </div>

        <div id='section-4'>
          <div className='inner'>
            <div className='head'>
              <h3>Solutions Blueprint</h3>
              <p>
                DEVtrust implemented several innovative solutions to address
                these challenges
              </p>
            </div>
            <div className='icon-text-box-container'>
              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/unified-management.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Unified School Management Platform</h4>
                  <p>
                    We developed a web application that simplifies curriculum
                    creation, class management, and student registration
                    into a single streamlined platform. By providing AI-powered
                    prompts for curriculum development, the platform reduces
                    administrative burden and enhances efficiency, creating a
                    smoother and more intuitive experience for educators and
                    administrators alike.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/integrated-communication.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Integrated Communication Features</h4>
                  <p>
                    The platform features advanced messaging capabilities,
                    including an OpenAI-powered chatbot that provides specific
                    responses to queries. This enables direct communication
                    between teachers and students, facilitates to-do list
                    management, and sends notifications, enhancing overall user
                    communication efficiency.
                  </p>
                  <div className='api-logos'>
                    <img
                      src='/caseStudies/newCaseStudies/ic_openAI.svg'
                      alt='openAI API'
                      title='openAI API'
                    />
                  </div>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/advanced-progress.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Advanced Progress Tracking and Leaderboards</h4>
                  <p>
                    We implemented a dynamic leaderboard and progress tracking
                    system, providing real-time insights into student
                    achievements and performance, resulting in an increase in
                    engagement and motivation among students.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/AI-enhanced.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI-Enhanced Learning Tools</h4>
                  <p>
                    The integration of AI APIs for video content, assessments,
                    and testing transformed the learning experience on EdTech
                    AI. By incorporating interactive and personalized
                    educational tools, the platform now offers engaging and
                    tailored learning opportunities for students. These
                    AI-driven features facilitate more effective learning by
                    adapting to individual student needs, providing real-time
                    feedback, and enhancing the overall educational process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='section-5'>
          <img
            src='/caseStudies/newCaseStudies/edtech-section5-cover.jpg'
            alt=''
          />
          <div className='textbox'>
            <div className='head'>
              <img src='/caseStudies/newCaseStudies/edtech-icon.svg' />
              <h3>
                Impact and <br /> Achievements
              </h3>
              <p>The implementation of solutions yielded impressive results</p>
            </div>
            <div className='listbox'>
              <div className='list-item'>
                <h5>Streamlined School Management</h5>
                <p>
                  The unified platform reduced administrative overhead by 50%,
                  leading to a 30% increase in overall operational efficiency
                  and a smoother management experience for school
                  administrators.
                </p>
              </div>
              <div className='list-item'>
                <h5>Enhanced Communication Efficiency</h5>
                <p>
                  Integrated AI-powered messaging and notification features
                  resulted in a 40% improvement in communication efficiency
                  between teachers and students, facilitating better interaction
                  and timely feedback.
                </p>
              </div>
              <div className='list-item'>
                <h5>Improved Student Engagement</h5>
                <p>
                  The introduction of progress tracking and leaderboard AI
                  gamification led to a 35% increase in student engagement and
                  motivation, as students were more actively involved in
                  monitoring and improving their performance.
                </p>
              </div>
              <div className='list-item'>
                <h5>Advanced Learning Integration</h5>
                <p>
                  AI-driven features improved learning outcomes by 25%,
                  enhancing student interaction with educational content and
                  assessments, and providing a more engaging and effective
                  learning environment.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id='section-6'>
          <div className='container'>
            <div className='head'>
              <h3>EdTech AI&apos;s user-friendly interface</h3>
              <p>
                Below are images showcasing EdTech AI, an advanced platform
                integrating AI for smarter curriculum creation, student-teacher
                interactions, and performance monitoring.
              </p>
            </div>
            <div className='portfolio'>
              <img
                src='/caseStudies/newCaseStudies/edtech-portfolio-1.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/edtech-portfolio-2.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/edtech-portfolio-3.jpg'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesEdtechAi;
