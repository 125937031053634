import React from 'react';
import '../../../../src/newCaseStudies.css';

const CaseStudiesMoeenmed = () => {
  return (
    <>
      <div id='site-content' className='moeenmed'>
        <div id='section-1' className='hero-section'>
          <div className='block-text'>
            <h1>
              <span>Moeen</span> <br /> Med
            </h1>
            <span className='s-divider'></span>
            <p>
              A Nabla API & OpenAI-powered tool designed to automate and enhance
              physician documentation through HIPAA-compliant transcription and
              Clinical Note generation.
            </p>
          </div>
          <img
            src='/caseStudies/newCaseStudies/moeenmed-hero.png'
            className='hero-image'
            alt='Moeen Med'
          />
        </div>

        <div id='section-2'>
          <div className='container'>
            <h2>Introduction</h2>
            <p>
              The client contacted DEVtrust to build “MoeenMed”, a tool for
              automating the documentation of physicians with the help of OpenAI
              and Nabla API.
              <br />
              DEVtrust intended to work on transcribing the conversation between
              the patient and the physician by creating a HIPAA-compliant
              Clinical note (treatment plan).
            </p>
          </div>
        </div>

        <div id='section-3'>
          <div className='container imagebox'>
            <div className='text-block'>
              <img src='/caseStudies/newCaseStudies/moeenmed-logo.png' />
              <h2>
                <span>Challenges</span>
                <br /> at a glance
              </h2>
              <p>
                Here are the challenges we encountered during the
                application&apos;s development phase
              </p>
            </div>
            <img
              src='/caseStudies/newCaseStudies/moeenmed-sec3.png'
              className='right-img'
            />
          </div>
          <div className='container textbox-column col-3'>
            <div className='textbox'>
              <span className='marker'>1</span>
              <h3>Documentation Time</h3>
              <p>
                Physicians often spend plenty of time documenting patient
                information, treatment plans, and other medical details
                resulting in significant overheads.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>2</span>
              <h3>Compliance</h3>
              <p>
                Medical documentation must adhere to strict legal and regulatory
                standards.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>3</span>
              <h3>Distraction</h3>
              <p>
                Significant distraction from addressing the core area
                (consultation) due to the manual note-taking process.
              </p>
            </div>
          </div>
        </div>

        <div id='section-4'>
          <div className='inner'>
            <div className='head'>
              <h3>Solutions Blueprint</h3>
              <p>
                DEVtrust implemented several innovative solutions to address
                these challenges
              </p>
            </div>
            <div className='icon-text-box-container'>
              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/automation.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Automated Documentation</h4>
                  <p>
                    MoeenMed can automatically generate documentation, including
                    detailed treatment plans, powered by AI. This feature saves
                    physicians considerable time by streamlining the creation of
                    medical records. Used Azure speech to-text and Nebula API
                    for automated documentation and transcription.
                  </p>
                  <div className='api-logos'>
                    <img
                      src='/caseStudies/newCaseStudies/ic_Azure.svg'
                      alt='Azure API'
                      title='Azure API'
                    />
                    <img
                      src='/caseStudies/newCaseStudies/nabla-logo.svg'
                      alt='Nebula API'
                      title='Nebula API'
                    />
                  </div>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img src='/caseStudies/newCaseStudies/accuracy.svg' alt='' />
                </div>
                <div className='textbox'>
                  <h4>Accuracy</h4>
                  <p>
                    AI-generated documents ensure more accuracy and completeness
                    based on Standardized templates that adhere to the legal and
                    regulatory standards.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img src='/caseStudies/newCaseStudies/hippa.svg' alt='' />
                </div>
                <div className='textbox'>
                  <h4>HIPAA Compliance</h4>
                  <p>The documents are HIPAA compliant.</p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/responsibility.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Focus on Core Responsibilities</h4>
                  <p>
                    The MoeenMed App handles the administration and
                    documentation, allowing physicians to focus more on their
                    primary clinical responsibilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='section-5'>
          <img src='/caseStudies/newCaseStudies/section5-cover.png' alt='' />
          <div className='textbox'>
            <div className='head'>
              <img src='/caseStudies/newCaseStudies/moeenmed-logo.png' />
              <h3>
                Impact and <br /> Achievements
              </h3>
              <p>The implementation of solutions yielded impressive results</p>
            </div>
            <div className='listbox'>
              <div className='list-item'>
                <h5>Time Efficiency</h5>
                <p>
                  OpenAI (ChatGPT 4.0) completes the documentation and
                  administration work in 60% less time.
                </p>
              </div>
              <div className='list-item'>
                <h5>Reduced Overhead</h5>
                <p>
                  Azure speech-to-text convertor-driven transcription reduced
                  the overhead by 80%.
                </p>
              </div>
              <div className='list-item'>
                <h5>Data Confidentiality</h5>
                <p>
                  HIPAA-compliant generated Clinical notes ensured 100% data
                  confidentiality.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id='section-6'>
          <div className='container'>
            <div className='head'>
              <h3>Moeen Med&apos;s user-friendly interface</h3>
              <p>
                Below are images showcasing MoeenMed&apos;s user-friendly
                interface, powered by OpenAI and Nabla API for HIPAA-compliant
                clinical documentation automation.
              </p>
            </div>
            <div className='portfolio'>
              <img
                src='/caseStudies/newCaseStudies/moeenmed-portfolio-1.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/moeenmed-portfolio-2.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/moeenmed-portfolio-3.jpg'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesMoeenmed;
