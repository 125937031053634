import axios from '../api/index';

export const getBlogs = async () => {
  try {
    const { data } = await axios.get('wp/v2/posts?_embed=wp:term');
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getTestimonial = async () => {
  try {
    const { data } = await axios.get('wp/v2/testimonial');
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getImage = async (id) => {
  try {
    const { data } = await axios.get(`wp/v2/media/${id}`);
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getHeader = async () => {
  try {
    const { data } = await axios.get(`wp/v2/home`);
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getPortfolioBanner = async () => {
  try {
    const { data } = await axios.get('wp/v2/portfolio_banner/?per_page=100');
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getTechnologies = async () => {
  try {
    const { data } = await axios.get('wp/v2/our_technologies/?per_page=100');
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getBusinessCase = async () => {
  try {
    const { data } = await axios.get('wp/v2/our_clients/?per_page=100');
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getOurProjects = async () => {
  try {
    const { data } = await axios.get('wp/v2/our_projects');
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getProcess = async () => {
  try {
    const { data } = await axios.get('/wp/v2/offering');
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const aboutUsData = async () => {
  try {
    const { data } = await axios.get('/wp/v2/about_us');
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getContactInfo = async () => {
  try {
    const { data } = await axios.get('/wp/v2/contact_info');
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const ourProcess = async () => {
  try {
    const { data } = await axios.get('/wp/v2/our_process');
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const submitForm = async (formdata) => {
  try {
    return await axios(
      'https://wpapi.devtrust.biz/wp-json/contact/v1/submit-form',
      {
        method: 'post',
        data: formdata,
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
  } catch (err) {
    console.log(err);
  }
};

/* This is used to load caseStudies */
export const getCaseStudies = async () => {
  try {
    const { data } = await axios.get(
      'https://wpapi.devtrust.biz/wp-json/wp/v2/_case_studies?per_page=100'
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

/* This is used to search caseStudies */
export const searchCaseStudies = async (params) => {
  try {
    const { data } = await axios.get(
      'https://wpapi.devtrust.biz/wp-json/wp/v2/_case_studies?search=' + params
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

/* This is used to loadMore caseStudies */
export const loadMoreCaseStudies = async (perPage) => {
  try {
    const { data } = await axios.get(
      'https://wpapi.devtrust.biz/wp-json/wp/v2/_case_studies?per_page=' +
        perPage
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};
