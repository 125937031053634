import React from 'react';

const CaseStudiesChatbot = () => {
  return (
    <>
      <div id='site-content' className='chatbot'>
        <div id='section-1' className='hero-section'>
          <div className='block-text'>
            <h1>ChatBot</h1>
            <span className='s-divider'></span>
            <p>
              DEVtrust ChatBot POC: Leveraging AI for Dynamic PDF Querying and
              Response Generation
            </p>
          </div>
          <img
            src='/caseStudies/newCaseStudies/chatbot-hero.jpg'
            className='hero-image'
          />
        </div>

        <div id='section-2'>
          <div className='container'>
            <h2>Introduction</h2>
            <p>
              We aimed to build a Proof of Concept (POC) for a ChatBot platform
              that could extract and provide precise answers from PDF documents,
              focusing on providing a seamless question-and-answer experience.
              The goal was to leverage AI and ML technologies to demonstrate
              apractical use case for dynamically querying structured content
              from their website.
            </p>
          </div>
        </div>

        <div id='section-3'>
          <div className='container imagebox'>
            <div className='text-block'>
              <img src='/caseStudies/newCaseStudies/chatbot-icon.svg' />
              <h2>
                <span>Challenges</span>
                <br /> at a glance
              </h2>
              <p>
                Here are the challenges we encountered during the
                application&apos;s development phase
              </p>
            </div>
            <img
              src='/caseStudies/newCaseStudies/chatbot-sec3-cover.png'
              className='right-img'
            />
          </div>
          <div className='container textbox-column col-3'>
            <div className='textbox'>
              <span className='marker'>1</span>
              <h3>Content Extraction and Querying</h3>
              <p>
                Ensuring the ChatBot accurately extracts information from the
                uploaded PDF document and handle contextually complex questions
                to deliver precise answers.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>2</span>
              <h3>Technical Implementation</h3>
              <p>
                Integrating OpenAI&apos;s GPT model with Django for backend
                processing along with storing and retrieving data efficiently
                with SQLite3.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>3</span>
              <h3>Extensibility</h3>
              <p>
                Building the POC with an option to enable file upload for
                broader use casesand ensuring the architecture could scale to
                accommodate more documents and advanced queries in the future.
              </p>
            </div>
          </div>
        </div>

        <div id='section-4'>
          <div className='inner'>
            <div className='head'>
              <h3>Solutions Blueprint</h3>
              <p>
                DEVtrust implemented several innovative solutions to address
                these challenges
              </p>
            </div>
            <div className='icon-text-box-container'>
              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/chatbot-ai-driven.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI-Driven Content Analysis</h4>
                  <p>
                    Utilized OpenAI&apos;s GPT model to extract content from the
                    PDF pages and optimized the ChatBot to accurately parse and
                    respond to user queries related to the extracted content.
                  </p>
                  <div className='api-logos'>
                    <img
                      src='/caseStudies/newCaseStudies/ic_openAI.svg'
                      alt='openAI API'
                      title='openAI API'
                    />
                  </div>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/chatbot-user-centric.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>User-Centric Interface Design</h4>
                  <p>
                    Developed a simple and responsive web-based interface using
                    Bootstrap to enhance user experience and enabled optional
                    functionality for users to upload their own PDFs for
                    querying.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/chatbot-scalable-architecture.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Scalable Architecture</h4>
                  <p>
                    Designed the POC with a modular approach, allowing future
                    upgrades like multi-document querying and expanded ML
                    capabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='section-5'>
          <img
            src='/caseStudies/newCaseStudies/chatbot-section5-cover.jpg'
            alt=''
          />
          <div className='textbox'>
            <div className='head'>
              <img src='/caseStudies/newCaseStudies/chatbot-icon.svg' />
              <h3>
                Impact and <br /> Achievements
              </h3>
              <p>The implementation of solutions yielded impressive results</p>
            </div>
            <div className='listbox'>
              <div className='list-item'>
                <h5>Operational Efficiency</h5>
                <p>
                  Successfully demonstrated the capability of querying
                  structured content from PDFs, with 90% query accuracy.
                </p>
              </div>
              <div className='list-item'>
                <h5>Improved Information Accessibility</h5>
                <p>
                  Enabled faster access to relevant information, reducing manual
                  search time by 50%.
                </p>
              </div>
              <div className='list-item'>
                <h5>Enhanced User Experience</h5>
                <p>
                  The simple and intuitive interface allowed users to interact
                  seamlessly with the ChatBot.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id='section-6'>
          <div className='container'>
            <div className='head'>
              <h3>ChatBot&apos;s user-friendly interface</h3>
              <p>
                Below are images showcasing the Proof of Concept (POC) ChatBot
                platform, designed to deliver precise answers from PDF documents
                through AI and ML technologies for a seamless
                question-and-answer experience.
              </p>
            </div>
            <div className='portfolio'>
              <img
                src='/caseStudies/newCaseStudies/chatbot-portfolio-1.jpg'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesChatbot;
