import React from 'react';

const CaseStudiesHealthbot = () => {
  return (
    <>
      <div id='site-content' className='healthbot'>
        <div id='section-1' className='hero-section'>
          <div className='block-text'>
            <h1>
              Health Bot/
              <br /> Calorie Bot
            </h1>
            <span className='s-divider'></span>
            <p>
              Health Bot POC: Leveraging AI for Personalized Diet Planning and
              Recommendations
            </p>
          </div>
          <img
            src='/caseStudies/newCaseStudies/healthbot-hero.jpg'
            className='hero-image'
          />
        </div>

        <div id='section-2'>
          <div className='container'>
            <h2>Introduction</h2>
            <p>
              Health Bot, also known as Calorie Bot, a platform to assist users
              in creating personalized diet plans tailored to their specific
              needs and preferences. The proof-of-concept (POC) aimed to
              leverage AI technology to enhance user interaction, simplify diet
              planning, and provide suggestions for healthier living.
            </p>
          </div>
        </div>

        <div id='section-3'>
          <div className='container imagebox'>
            <div className='text-block'>
              <img src='/caseStudies/newCaseStudies/healthbot-icon.svg' />
              <h2>
                <span>Challenges</span>
                <br /> at a glance
              </h2>
              <p>
                Here are the challenges we encountered during the
                application&apos;s development phase
              </p>
            </div>
            <img
              src='/caseStudies/newCaseStudies/healthbot-sec3-cover.png'
              className='right-img'
            />
          </div>
          <div className='container textbox-column col-3'>
            <div className='textbox'>
              <span className='marker'>1</span>
              <h3>Interactive and Personalized User Experience</h3>
              <p>
                Designing a chatbot capable of understanding user inputs and
                generating highly customized diet plans and ensuring the
                bot&apos;s questions and recommendations align with user health
                goals.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>2</span>
              <h3>AI Integration</h3>
              <p>
                Utilizing AI to interpret user responses and create diet plans
                dynamically and providing intelligent responses for inquiries
                about alternative diet options or foods.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>3</span>
              <h3>Scalability and Mobile Integration Potential</h3>
              <p>
                Building a scalable solution that could integrate seamlessly
                with mobile apps in the future and preparing for advanced
                features like calorie tracking and real-time notifications.
              </p>
            </div>
          </div>
        </div>

        <div id='section-4'>
          <div className='inner'>
            <div className='head'>
              <h3>Solutions Blueprint</h3>
              <p>
                DEVtrust implemented several innovative solutions to address
                these challenges
              </p>
            </div>
            <div className='icon-text-box-container'>
              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/healthbot-ai-driven.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI-Driven Chatbot for Personalized Diet Planning</h4>
                  <p>
                    Developed a chatbot powered by OpenAI to interact with users
                    and generate custom diet plans based on responses, also the
                    bot to handle follow-up inquiries, offering alternative food
                    suggestions and refining the diet plan as needed.
                  </p>
                  <div className='api-logos'>
                    <img
                      src='/caseStudies/newCaseStudies/ic_openAI.svg'
                      alt='openAI API'
                      title='openAI API'
                    />
                  </div>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/healthbot-web-based.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Web-Based POC with Scalable Architecture</h4>
                  <p>
                    Scalable Architecture Robust architecture to handle
                    increasing user demands and workloads by dynamically
                    allocating resources without compromising performance.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/healthbot-efficient-data.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Efficient Data Management</h4>
                  <p>
                    Utilized relational database for lightweight and efficient
                    data management during the POC stage.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='section-5'>
          <img
            src='/caseStudies/newCaseStudies/healthbot-section5-cover.jpg'
            alt=''
          />
          <div className='textbox'>
            <div className='head'>
              <img src='/caseStudies/newCaseStudies/healthbot-icon.svg' />
              <h3>
                Impact and <br /> Achievements
              </h3>
              <p>The implementation of solutions yielded impressive results</p>
            </div>
            <div className='listbox'>
              <div className='list-item'>
                <h5>Enhanced User Engagement</h5>
                <p>
                  The chatbot provided an intuitive and interactive diet
                  planning experience, leading to a 40% increase in user
                  engagement compared to static diet planning tools.
                </p>
              </div>
              <div className='list-item'>
                <h5>Operational Efficiency</h5>
                <p>
                  Nutritionists utilize this chatbot to automate diet planning
                  thus reducing manual effort by 90% as the chatbot generates
                  diet lists automatically.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id='section-6'>
          <div className='container'>
            <div className='head'>
              <h3>Health Bot/Calorie Bot&apos;s user-friendly interface</h3>
              <p>
                Below are images showcasing Health Bot, also known as Calorie
                Bot, a POC platform leveraging AI to create personalized diet
                plans and provide tailored suggestions for healthier living.
              </p>
            </div>
            <div className='portfolio'>
              <img
                src='/caseStudies/newCaseStudies/healthbot-portfolio-1.jpg'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesHealthbot;
