import React, { useRef, useState } from 'react';
//import ReCAPTCHA from 'react-google-recaptcha';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { submitForm } from '../../utils';
import SuccessAlert from '../../shared/alert/SuccessAlert';
import { setLoader } from '../../redux/Action/actions';
import { useDispatch } from 'react-redux';

function FormComponent({ handleBookCall }) {
  const fileInputRef = useRef();
  const [filePreviews, setFilePreviews] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      contact: '',
      comment: '',
      files: [],
      ndaRequest: false,
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .required('Full name is required')
        .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed')
        .min(2, 'Must be at least 2 characters')
        .max(30, 'Must be 30 characters or less')
        .trim(),
      email: Yup.string()
        .required('Email address is required')
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Invalid email address'
        )
        .trim(),
      contact: Yup.string()
        .required('Mobile number is required')
        .matches(/^[0-9]*$/, 'Only numbers are allowed')
        .min(10, 'Must be at least 10 characters')
        .max(12, 'Must be at most 12 characters')
        .trim(),
      comment: Yup.string()
        .required('Comment is required')
        .matches(/^[\w\s]+$/, 'Only words are allowed')
        .min(1, 'Must be at least 1 word')
        .max(200, 'Must be at most 200 characters')
        .trim(),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(setLoader(true));
      let formdata = new FormData();
      formdata.append('fullname', values.fullname);
      formdata.append('email', values.email);
      formdata.append('contact', values.contact);
      formdata.append('comment', values.comment);
      formdata.append('ndaRequest', values.ndaRequest);

      for (let i = 0; i < values.files.length; i++) {
        formdata.append('upload[]', values.files[i]);
      }

      let res = await submitForm(formdata);
      if (res.status === 200) {
        dispatch(setLoader(false));
        setModalOpen(true);
        setTimeout(() => {
          setModalOpen(false);
        }, 7000);
        setFilePreviews([]);
        resetForm();
      } else {
        alert('Something went wrong.');
      }
    },
  });

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];
    const previews = [];

    if (files.length > 10) {
      alert('You can upload a maximum of 10 files.');
      return;
    }

    files.forEach((file) => {
      if (
        file.size <= 5 * 1024 * 1024 &&
        [
          'image/jpeg',
          'image/png',
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ].includes(file.type)
      ) {
        validFiles.push(file);

        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          previews.push(URL.createObjectURL(file));
        } else if (file.type === 'application/pdf') {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/PDF.png'
          );
        } else if (file.type === 'application/msword') {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Doc.png'
          );
        } else if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Docx.png'
          );
        } else if (file.type === 'application/vnd.ms-excel') {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/XLS.png'
          );
        } else if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/EXL.png'
          );
        } else if (file.type === 'text/csv') {
          previews.push(
            'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/CSV.png'
          );
        }
      } else {
        alert(
          `File ${file.name} is not allowed. Ensure it's a JPG, PNG, DOC, DOCX, or PDF under 5MB.`
        );
      }
    });

    formik.setFieldValue('files', validFiles);
    setFilePreviews(previews);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const removeFile = (index) => {
    const newFiles = [...formik.values.files];
    newFiles.splice(index, 1);

    const previews = newFiles.map((file) => {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        return URL.createObjectURL(file);
      } else if (file.type === 'application/pdf') {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/PDF.png';
      } else if (file.type === 'application/msword') {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Doc.png';
      } else if (
        file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Docx.png';
      } else if (file.type === 'application/vnd.ms-excel') {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/XLS.png';
      } else if (
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/EXL.png';
      } else if (file.type === 'text/csv') {
        return 'https://wpapi.devtrust.biz/wp-content/uploads/2024/12/CSV.png';
      }
      return null;
    });

    formik.setFieldValue('files', newFiles);
    setFilePreviews(previews);
  };

  return (
    <div className='banner_form_sec w-full'>
      <div className='book_btn_block flex  justify-end mb-3'>
        <button
          type='button'
          className='btn btn_schedule bg-gray-500 text-white py-2 px-4 rounded'
          onClick={handleBookCall}
        >
          Book a call
          <img src='/img/call_icon.gif' alt='call icon' />
        </button>
      </div>
      {!isModalOpen ? (
        <>
          <h2 className='text-[#43BDC1] text-[40px] text-left font-semibold'>
            Start A Conversation
          </h2>
          <form className='mt-5' onSubmit={formik.handleSubmit}>
            <div className='form-group mb-3'>
              <input
                type='text'
                name='fullname'
                className={`form-control w-full py-2 ${
                  formik.touched.fullname && formik.errors.fullname
                    ? 'is-invalid'
                    : ''
                }`}
                placeholder='Your name*'
                value={formik.values.fullname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.fullname && formik.errors.fullname ? (
                <div className='text-left text-red-600'>
                  {formik.errors.fullname}
                </div>
              ) : null}
            </div>
            <div className='form-group mb-3'>
              <input
                type='email'
                name='email'
                className={`form-control w-full py-2 ${
                  formik.touched.email && formik.errors.email
                    ? 'is-invalid'
                    : ''
                }`}
                placeholder='Your email address*'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='text-left text-red-600'>
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
            <div className='form-group mb-3'>
              <input
                type='text'
                name='contact'
                className='form-control py-2 w-full'
                placeholder='Mobile number'
                value={formik.values.contact}
                onChange={formik.handleChange}
              />
              {formik.touched.contact && formik.errors.contact ? (
                <div className='text-left text-red-600'>
                  {formik.errors.contact}
                </div>
              ) : null}
            </div>
            <div className='form-group mb-3'>
              <textarea
                name='comment'
                className={`form-control py-2 w-full ${
                  formik.touched.comment && formik.errors.comment
                    ? 'is-invalid'
                    : ''
                }`}
                rows='3'
                placeholder='How can we help you?*'
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              ></textarea>
              {formik.touched.comment && formik.errors.comment ? (
                <div className='text-left text-red-600'>
                  {formik.errors.comment}
                </div>
              ) : null}
            </div>
            <div className='flex flex-col md:flex-row gap-3 justify-between items-left md:items-center py-4'>
              <div>
                <button
                  className='upload_btn flex items-center space-x-2 border border-blue-500 bg-blue-500 p-2 rounded'
                  type='button'
                  onClick={handleUploadClick}
                >
                  <img
                    src='img/upload-icon.png'
                    alt='upload-icon'
                    className='h-5'
                  />
                  <span>Upload Files</span>
                </button>
                <input
                  type='file'
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                  accept='image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv'
                  multiple
                />
              </div>
              {/*<ReCAPTCHA
              size='normal'
              sitekey='Your client site key'
              onChange={() => console.log('Captcha completed')}
            /> */}
            </div>
            {filePreviews.length > 0 && (
              <div className='mt-3'>
                <h4 className='text-lg text-left'>Preview Files:</h4>
                <div className='flex flex-wrap gap-2'>
                  {filePreviews.map((filePreview, index) => (
                    <div key={index} className='preview_get_form  relative'>
                      <img
                        src={filePreview}
                        alt={`File Preview ${index + 1}`}
                        className='h-20 w-20 object-cover border rounded'
                      />
                      <button
                        type='button'
                        className='remove_btn absolute top-0 right-0 p-1 text-red-600 bg-white rounded-full'
                        onClick={() => removeFile(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className='card_buttons flex items-center justify-between space-x-1'>
              <div className='form-check text-start'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='ndaRequest'
                  name='ndaRequest'
                  checked={formik.values.ndaRequest}
                  onChange={formik.handleChange}
                />
                <label className='form-check-label ml-2' htmlFor='ndaRequest'>
                  Request for NDA
                </label>
              </div>
              <button
                type='submit'
                className='btn btn_inquire bg-blue-500 text-white py-2 px-4 rounded hover:bg-[#0A0A0A]'
              >
                Submit
              </button>
            </div>
          </form>
        </>
      ) : (
        <SuccessAlert
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          title='Thank You!'
          message='We have noted your request and will be in contact shortly.'
        />
      )}
    </div>
  );
}

export default FormComponent;
