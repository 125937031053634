import React from 'react';

const CaseStudiesFinancialChatbot = () => {
  return (
    <>
      <div id='site-content' className='financial-chatbot'>
        <div id='section-1' className='hero-section'>
          <div className='block-text'>
            <h1>
              <span>Financial</span> <br /> Chatbot
            </h1>
            <span className='s-divider'></span>
            <p>
              An AI-powered platform for investors to provide real-time insights
              into financial markets to enable better decision making.
            </p>
          </div>
          <img
            src='/caseStudies/newCaseStudies/fc-hero.jpg'
            className='hero-image'
          />
        </div>

        <div id='section-2'>
          <div className='container'>
            <h2>Introduction</h2>
            <p>
              The Financial Chatbot is an advanced platform designed for
              investors, offering AI-powered tools and insights to navigate the
              fast-paced financial markets. Recognizing the need for a robust,
              data-driven solution, Financial Chatbot partnered with Devtrust to
              create an all-encompassing platform that integrates real-time
              financial data and uses AI to empower users with actionable
              insights, enabling them to make informed investment decisions.
            </p>
          </div>
        </div>

        <div id='section-3'>
          <div className='container imagebox'>
            <div className='text-block'>
              <img src='/caseStudies/newCaseStudies/fc-section5-icon.svg' />
              <h2>
                <span>Challenges</span>
                <br /> at a glance
              </h2>
              <p>
                Financial Chatbot encountered several challenges in its mission
                to support investors
              </p>
            </div>
            <img
              src='/caseStudies/newCaseStudies/fc-sec3.png'
              className='right-img'
            />
          </div>
          <div className='container textbox-column col-4'>
            <div className='textbox'>
              <span className='marker'>1</span>
              <h3>Financial Data Integration</h3>
              <p>
                Aggregating accurate and real-time financial data from multiple
                sources was complex and resource-intensive.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>2</span>
              <h3>User Engagement</h3>
              <p>
                Providing a seamless and interactive user experience to engage
                investors and meet their financial needs.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>3</span>
              <h3>Investment Insights</h3>
              <p>
                Offering advanced tools to analyze stock performance, compare
                assets, and predict financial trends effectively.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>4</span>
              <h3>Crypto Data Accessibility</h3>
              <p>
                Ensuring up-to-date cryptocurrency prices and relevant financial
                news are readily available to users.
              </p>
            </div>
          </div>
        </div>

        <div id='section-4'>
          <div className='inner'>
            <div className='head'>
              <h3>Solutions Blueprint</h3>
              <p>
                DEVtrust implemented several innovative solutions to address
                these challenges.
              </p>
            </div>
            <div className='icon-text-box-container'>
              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/financial-data.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Financial Data</h4>
                  <p>
                    Integrated Open AI, TradingView, Yahoo Finance, FinnHub,
                    TradingEconomics, and CryptoCompare APIs to provide
                    comprehensive and accurate financial stats.
                  </p>
                  <div className='api-logos'>
                    <img
                      src='/caseStudies/newCaseStudies/ic_openAI.svg'
                      alt='openAI API'
                      title='openAI API'
                    />
                    <img
                      src='/caseStudies/newCaseStudies/ic_tradingview.svg'
                      alt='TradingView API'
                      title='TradingView API'
                    />
                    <img
                      src='/caseStudies/newCaseStudies/ic_yahoofinance.svg'
                      alt='Yahoo Finance API'
                      title='Yahoo Finance API'
                    />
                    <img
                      src='/caseStudies/newCaseStudies/ic_Finnhub.svg'
                      alt='FinnHub API'
                      title='FinnHub API'
                    />
                    <img
                      src='/caseStudies/newCaseStudies/ic_TE.svg'
                      alt='TradingEconomics API'
                      title='TradingEconomics API'
                    />
                    <img
                      src='/caseStudies/newCaseStudies/ic_cryptocompare.svg'
                      alt='CryptoCompare API'
                      title='CryptoCompare API'
                    />
                  </div>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/financial-chatbot.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Financial Chatbot</h4>
                  <p>
                    Developed a Financial Chatbot using prompt engineering and
                    OpenAI integration to cater to various financial needs, such
                    as checking stock prices, comparing stocks, and handling
                    stock predictions.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/user-engagement.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>User Engagement</h4>
                  <p>
                    Designed an intuitive user interface to enhance user
                    experience, making financial information easily accessible
                    and actionable.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/investment-insights.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Investment Insights</h4>
                  <p>
                    Emphasized understanding the beta of assets and portfolios,
                    enabling users to optimize their investments based on
                    desired risk levels.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='section-5'>
          <img src='/caseStudies/newCaseStudies/fc-section5-cover.jpg' alt='' />
          <div className='textbox'>
            <div className='head'>
              <img src='/caseStudies/newCaseStudies/fc-section5-icon.svg' />
              <h3>
                Impact and <br /> Achievements
              </h3>
              <p>The implementation of solutions yielded impressive results</p>
            </div>
            <div className='listbox'>
              <div className='list-item'>
                <h5>Enhanced Data Access</h5>
                <p>
                  Integrated AI APIs provided users with real-time access to
                  comprehensive financial data, improving decision-making by
                  50%.
                </p>
              </div>
              <div className='list-item'>
                <h5>Interactive User Experience</h5>
                <p>
                  The Financial Chatbot increased user engagement and
                  satisfaction with the help of AI, reducing the time spent
                  searching for information by 40%.
                </p>
              </div>
              <div className='list-item'>
                <h5>Improved Investment Insights</h5>
                <p>
                  Advanced AI tools and analytics empowered users to make
                  informed decisions, optimizing their portfolios and enhancing
                  investment outcomes by 50%.
                </p>
              </div>
              <div className='list-item'>
                <h5>Up-to-Date Crypto Data</h5>
                <p>
                  Users could access current cryptocurrency prices and relevant
                  news, staying informed and making timely investment decisions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id='section-6'>
          <div className='container'>
            <div className='head'>
              <h3>Financial Chatbot&apos;s user-friendly interface</h3>
              <p>
                Below are images showcasing the Financial Chatbot&apos;s
                user-friendly interface, designed to deliver real-time market
                insights and AI-driven investment solutions.
              </p>
            </div>
            <div className='portfolio'>
              <img
                src='/caseStudies/newCaseStudies/fc-portfolio-1.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/fc-portfolio-2.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/fc-portfolio-3.jpg'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesFinancialChatbot;
