import React from 'react';

const CaseStudiesEliteMarketingSuite = () => {
  return (
    <>
      <div id='site-content' className='ems'>
        <div id='section-1' className='hero-section'>
          <div className='block-text'>
            <h1>
              Elite Marketing <br /> Suite
            </h1>
            <span className='s-divider'></span>
            <p>
              How Devtrust Leveraged AI to Enhance Lead Generation and Social
              Media Engagement
            </p>
          </div>
          <img
            src='/caseStudies/newCaseStudies/ems-hero.jpg'
            className='hero-image'
          />
        </div>

        <div id='section-2'>
          <div className='container'>
            <h2>Introduction</h2>
            <p>
              Elite Marketing Suite is an AI-driven Chrome extension designed to
              empower marketers and businesses on Facebook. By integrating
              cutting-edge AI technologies, including natural language
              processing (NLP) and machine learning (ML), Devtrust developed
              tools that automate engagement, generate personalized responses,
              and optimize lead generation. The suite enables users to
              streamline their interactions, build meaningful relationships, and
              scale their marketing efforts with unprecedented efficiency.
            </p>
          </div>
        </div>

        <div id='section-3'>
          <div className='container imagebox'>
            <div className='text-block'>
              <img src='/caseStudies/newCaseStudies/ems-icon.svg' />
              <h2>
                <span>Challenges</span>
                <br /> at a glance
              </h2>
              <p>
                Facebook marketers faced several challenges that limited their
                productivity and engagement effectiveness
              </p>
            </div>
            <img
              src='/caseStudies/newCaseStudies/ems-sec3-cover.png'
              className='right-img'
            />
          </div>
          <div className='container textbox-column'>
            <div className='textbox'>
              <span className='marker'>1</span>
              <h3>Time-Consuming Manual Processes</h3>
              <p>
                Managing comments, friend requests, and messages required
                extensive manual effort.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>2</span>
              <h3>Lack of Personalization</h3>
              <p>
                Generic responses reduced the impact of user engagement, failing
                to foster meaningful connections.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>3</span>
              <h3>Disorganized Lead Management</h3>
              <p>
                Tracking and categorizing potential leads was inefficient and
                error prone.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>4</span>
              <h3>Engagement Blind Spots</h3>
              <p>
                Identifying inactive leads and maintaining consistent
                communication were difficult without data-driven insights.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>5</span>
              <h3>Inefficient Campaign Tracking</h3>
              <p>
                Managing contests and campaign participants was tedious, lacking
                automation and analytics.
              </p>
            </div>
          </div>
        </div>

        <div id='section-4'>
          <div className='inner'>
            <div className='head'>
              <h3>Solutions Blueprint</h3>
              <p>
                DEVtrust implemented several innovative solutions to address
                these challenges
              </p>
            </div>
            <div className='icon-text-box-container'>
              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/ems-ai-comment.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI Comment Auto Reply</h4>
                  <ul>
                    <li>
                      Powered by NLP, this tool analyzes the context of comments
                      and generates intelligent, personalized replies.
                    </li>
                    <li>
                      Enhances user engagement by responding in a human-like
                      tone tailored to the conversation.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/ems-comment-generator.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI Comment Generator</h4>
                  <ul>
                    <li>
                      Uses ML algorithms to generate context-aware comments on
                      posts, boosting visibility and interactions.
                    </li>
                    <li>
                      Suggests optimal responses based on audience sentiment and
                      content themes.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/ems-broadcast.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI Broadcast Messaging</h4>
                  <ul>
                    <li>
                      AI-enhanced personalization creates messages that resonate
                      with targeted groups, improving open and response rates.
                    </li>
                    <li>
                      Automates bulk message sending while maintaining relevance
                      and individualization.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/ems-content-tracker.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI Contest Tracker</h4>
                  <ul>
                    <li>
                      Tracks engagement metrics like likes, shares, and comments
                      using ML-based analysis.
                    </li>
                    <li>
                      Identifies winners, analyzes participation trends, and
                      generates actionable insights for campaign optimization.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/ems-friend-management.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI Friend Management Tools</h4>
                  <ul>
                    <li>
                      NLP and ML algorithms detect and remove inactive or
                      deactivated accounts in bulk.
                    </li>
                    <li>
                      Automatically approves or cancels friend requests based on
                      user-defined criteria.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/ems-crm-integration.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI-Powered CRM Integration</h4>
                  <ul>
                    <li>
                      Tags, categorizes, and scores leads using AI-driven
                      analysis of interaction patterns.
                    </li>
                    <li>
                      Provides actionable recommendations for nurturing leads
                      and optimizing conversion efforts.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/ems-post-lover.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Post Lover with AI Insights</h4>
                  <ul>
                    <li>
                      Automatically reacts to posts using AI-driven relevance
                      scoring.
                    </li>
                    <li>
                      Ensures interactions align with user-defined engagement
                      strategies.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/ems-sentiments.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Sentiment Analysis for Feedback</h4>
                  <ul>
                    <li>
                      Analyzes user responses and comments to identify sentiment
                      trends.
                    </li>
                    <li>
                      Generates insights to refine engagement strategies and
                      messaging.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='section-5'>
          <img
            src='/caseStudies/newCaseStudies/ems-section5-cover.jpg'
            alt=''
          />
          <div className='textbox'>
            <div className='head'>
              <img src='/caseStudies/newCaseStudies/ems-icon.svg' />
              <h3>
                Impact and <br /> Achievements
              </h3>
              <p>The implementation of solutions yielded impressive results</p>
            </div>
            <div className='listbox'>
              <div className='list-item'>
                <h5>Enhanced Engagement Efficiency</h5>
                <ul>
                  <li>
                    65% reduction in manual effort through automated responses
                    and AI-guided interactions.
                  </li>
                  <li>
                    Personalized engagement boosted response rates by 40%,
                    creating stronger connections with audiences.
                  </li>
                </ul>
              </div>
              <div className='list-item'>
                <h5>Smarter Lead Management</h5>
                <ul>
                  <li>
                    AI tagging and categorization improved lead tracking
                    accuracy by 70%, ensuring high-value leads received priority
                    attention.
                  </li>
                  <li>
                    NLP-based sentiment analysis provided deeper insights into
                    audience needs and preferences.
                  </li>
                </ul>
              </div>
              <div className='list-item'>
                <h5>Improved Campaign Performance</h5>
                <ul>
                  <li>
                    AI Contest Tracker increased participant engagement by 50%,
                    offering real-time insights into campaign success.
                  </li>
                  <li>
                    Automated follow-ups with personalized messages improved
                    contest-to-conversion rates.
                  </li>
                </ul>
              </div>
              <div className='list-item'>
                <h5>Optimized Engagement Strategies</h5>
                <ul>
                  <li>
                    AI-powered friend management tools streamlined connections,
                    reducing inactive accounts by 80%.
                  </li>
                  <li>
                    Intelligent post reactions and comment generation increased
                    page visibility and boosted engagement metrics by 35%.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id='section-6'>
          <div className='container'>
            <div className='head'>
              <h3>EMS&apos;s user-friendly interface</h3>
              <p>
                Below are images showcasing the Elite Marketing Suite, an
                AI-driven Chrome extension that empowers marketers with
                automated engagement, personalized responses, and optimized lead
                generation on Facebook.
              </p>
            </div>
            <div className='portfolio'>
              <img
                src='/caseStudies/newCaseStudies/ems-portfolio-1.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/ems-portfolio-2.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/ems-portfolio-3.jpg'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesEliteMarketingSuite;
