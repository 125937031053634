import React from 'react';

const CaseStudiesTeleVisits = () => {
  return (
    <>
      <div id='site-content' className='televisits'>
        <div id='section-1' className='hero-section'>
          <div className='block-text'>
            <h1>TeleVisits</h1>
            <span className='s-divider'></span>
            <p>Revolutionizing Caregiver Support with AI-Enhanced IVR</p>
          </div>
          <img
            src='/caseStudies/newCaseStudies/televisits-hero.jpg'
            className='hero-image'
          />
        </div>

        <div id='section-2'>
          <div className='container'>
            <h2>Introduction</h2>
            <p>
              TeleVisits is a groundbreaking project designed to empower
              caregivers who lack access to smartphones or smart devices. By
              utilizing Twilio APIs and IVR (Interactive Voice Response)
              systems, Devtrust crafted an innovative solution for caregivers to
              track visits, provide feedback, and interact seamlessly. The
              integration of AI-driven technology elevated the platform,
              offering a smarter, more intuitive user experience.
            </p>
          </div>
        </div>

        <div id='section-3'>
          <div className='container imagebox'>
            <div className='text-block'>
              <img src='/caseStudies/newCaseStudies/televisits-icon.svg' />
              <h2>
                <span>Challenges</span>
                <br /> at a glance
              </h2>
              <p>
                Here are the challenges we encountered during the
                application&apos;s development phase
              </p>
            </div>
            <img
              src='/caseStudies/newCaseStudies/televisits-sec3-cover.png'
              className='right-img'
            />
          </div>
          <div className='container textbox-column col-4'>
            <div className='textbox'>
              <span className='marker'>1</span>
              <h3>Limited Device Access</h3>
              <p>
                Many caregivers lacked access to smartphones or internet-enabled
                devices, requiring a simpler solution for task management.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>2</span>
              <h3>Complex Navigation</h3>
              <p>
                Navigating traditional IVR systems often proved cumbersome and
                inefficient.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>3</span>
              <h3>Feedback Management</h3>
              <p>
                Collecting real-time feedback from caregivers was difficult,
                especially in environments where digital tools were unavailable.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>4</span>
              <h3>Accessibility</h3>
              <p>
                Ensuring an intuitive and user-friendly system for caregivers of
                varying technical proficiency.
              </p>
            </div>
          </div>
        </div>

        <div id='section-4'>
          <div className='inner'>
            <div className='head'>
              <h3>Solutions Blueprint</h3>
              <p>
                DEVtrust implemented several innovative solutions to address
                these challenges
              </p>
            </div>
            <div className='icon-text-box-container'>
              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/televisits-ivr-agent.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI-Based IVR Agent</h4>
                  <ul>
                    <li>
                      Leveraged natural language processing (NLP) to create an
                      AI-based IVR agent.
                    </li>
                    <li>
                      The AI agent enabled users to interact through spoken
                      commands, bypassing the need for keypad inputs.
                    </li>
                    <li>
                      This feature allowed caregivers to access information,
                      navigate options, and complete tasks in a conversational
                      manner, improving accessibility and user satisfaction.
                    </li>
                  </ul>
                  <div className='api-logos'>
                    <img
                      src='/caseStudies/newCaseStudies/ic_openAI.svg'
                      alt='openAI API'
                      title='openAI API'
                    />
                  </div>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/televisits-user-interaction.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>User Interaction</h4>
                  <ul>
                    <li>
                      Designed an IVR system where users could interact using
                      their phone&apos;s keypad or voice commands.
                    </li>
                    <li>
                      Caregivers navigated features effortlessly through simple
                      number selections or spoken prompts.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/televisits-feedback-recording.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Feedback Recording</h4>
                  <ul>
                    <li>
                      Enabled caregivers to record comments during visits by
                      speaking directly into their phones.
                    </li>
                    <li>
                      These voice recordings were securely stored and accessible
                      to administrators for actionable insights.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/televisits-visit-tracking.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Visit Tracking</h4>
                  <ul>
                    <li>
                      Integrated Twilio APIs to create a reliable and scalable
                      system that logged and tracked caregiver visits.
                    </li>
                    <li>
                      Ensured that the platform operated seamlessly across all
                      phone types, making it universally accessible.
                    </li>
                  </ul>
                  <div className='api-logos'>
                    <img
                      src='/caseStudies/newCaseStudies/ic_twilio.svg'
                      alt='Twilio API'
                      title='Twilio API'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='section-5'>
          <img
            src='/caseStudies/newCaseStudies/televisits-section5-cover.jpg'
            alt=''
          />
          <div className='textbox'>
            <div className='head'>
              <img src='/caseStudies/newCaseStudies/televisits-icon.svg' />
              <h3>
                Impact and <br /> Achievements
              </h3>
              <p>The implementation of solutions yielded impressive results</p>
            </div>
            <div className='listbox'>
              <div className='list-item'>
                <h5>Enhanced User Experience</h5>
                <ul>
                  <li>
                    The AI-based IVR agent transformed interactions into
                    conversational experiences, increasing user satisfaction by
                    65%.
                  </li>
                  <li>
                    Caregivers with no prior technical experience found the
                    system easy to use and navigate.
                  </li>
                </ul>
              </div>
              <div className='list-item'>
                <h5>Improved Accessibility</h5>
                <ul>
                  <li>
                    The ability to interact via voice or keypad made the system
                    accessible to caregivers with varying levels of technical
                    proficiency.
                  </li>
                  <li>
                    Caregivers without smartphones could now efficiently manage
                    visits and provide feedback.
                  </li>
                </ul>
              </div>
              <div className='list-item'>
                <h5>Streamlined Feedback Collection</h5>
                <ul>
                  <li>
                    Voice recording capabilities increased the quantity and
                    quality of feedback, improving response accuracy by 70%.
                  </li>
                  <li>
                    Administrators gained deeper insights into caregiver
                    activities and needs.
                  </li>
                </ul>
              </div>
              <div className='list-item'>
                <h5>Optimized Visit Management</h5>
                <ul>
                  <li>
                    The integration of Twilio APIs ensured a robust tracking
                    system, reducing visit reporting discrepancies by 50%.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id='section-6'>
          <div className='container'>
            <div className='head'>
              <h3>Televisits&apos;s user-friendly interface</h3>
              <p>
                Below are images showcasing TeleVisits, an innovative solution
                designed to empower caregivers with seamless visit tracking and
                feedback through Twilio APIs, IVR systems, and AI-driven
                technology.
              </p>
            </div>
            <div className='portfolio'>
              <img
                src='/caseStudies/newCaseStudies/televisits-portfolio-1.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/televisits-portfolio-2.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/televisits-portfolio-3.jpg'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesTeleVisits;
