import React from 'react';

const CaseStudiesCredenza = () => {
  return (
    <>
      <div id='site-content' className='credenza'>
        <div id='section-1' className='hero-section'>
          <div className='block-text'>
            <h1>Credenza</h1>
            <span className='s-divider'></span>
            <p>Transforming Credential Management with AI and Automation</p>
          </div>
          <img
            src='/caseStudies/newCaseStudies/credenza-hero.jpg'
            className='hero-image'
          />
        </div>

        <div id='section-2'>
          <div className='container'>
            <h2>Introduction</h2>
            <p>
              Credenza required a secure and user-friendly platform for doctors
              to manage and share their credentials. The system needed robust
              features for document management, automated form filling, and
              secure sharing. With scalability to support up to one million
              doctors, the platform aimed to streamline the credentialing
              process while ensuring HIPAA compliance and data protection.
            </p>
          </div>
        </div>

        <div id='section-3'>
          <div className='container imagebox'>
            <div className='text-block'>
              <img src='/caseStudies/newCaseStudies/credenza-icon.svg' />
              <h2>
                <span>Challenges</span>
                <br /> at a glance
              </h2>
              <p>
                Here are the challenges we encountered during the
                application&apos;s development phase
              </p>
            </div>
            <img
              src='/caseStudies/newCaseStudies/credenza-sec3-cover.png'
              className='right-img'
            />
          </div>
          <div className='container textbox-column col-4'>
            <div className='textbox'>
              <span className='marker'>1</span>
              <h3>Secure User Authentication and Access</h3>
              <p>
                Designing a multi-factor authentication (MFA) system for
                enhanced security, including authentication apps, biometrics,
                and passkeys and data protection with HIPAA-compliant storage
                for sensitive medical and professional information.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>2</span>
              <h3>Automated Data Extraction</h3>
              <p>
                Developing an intuitive way for users to upload documents,
                whether scanned documents, photos, or PDFs and then extract the
                data automatically.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>3</span>
              <h3>Application Management</h3>
              <p>
                Leveraging AI to scrape, categorize, and name uploaded documents
                and enabling seamless population of applications with the
                scraped data using OpenAI’s API.
              </p>
            </div>
            <div className='textbox'>
              <span className='marker'>4</span>
              <h3>Scalable & User-Friendly Design</h3>
              <p>
                Ensuring a cohesive and user-friendly interface with a
                relational database to handle high data volumes efficiently.
              </p>
            </div>
          </div>
        </div>

        <div id='section-4'>
          <div className='inner'>
            <div className='head'>
              <h3>Solutions Blueprint</h3>
              <p>
                DEVtrust implemented several innovative solutions to address
                these challenges
              </p>
            </div>
            <div className='icon-text-box-container'>
              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/credenza-robust-security.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Robust Security and Authentication</h4>
                  <p>
                    Developed a multi-factor authentication (MFA) system
                    supporting app-based authenticators, with future scalability
                    for biometric logins, and enforced HIPAA-aligned encryption
                    for data at rest and in transit, ensuring sensitive
                    credential protection.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/credenza-credential-management.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>AI-Powered Credential Management</h4>
                  <p>
                    Utilized OpenAI to process uploaded documents, extracting
                    key details, and categorizing them with intelligent naming
                    conventions, while automating form filling to save users
                    significant time and reduce manual errors.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/credenza-user-friendly.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Scalable and User-Friendly Design</h4>
                  <p>
                    Built the platform using Laravel Blade for the front end and
                    Laravel for the back end, ensuring a cohesive and
                    user-friendly interface, and designed with a MySQL database
                    to handle high data volumes efficiently.
                  </p>
                </div>
              </div>

              <div className='icon-text-box'>
                <div className='iconbox'>
                  <img
                    src='/caseStudies/newCaseStudies/credenza-third-party.svg'
                    alt=''
                  />
                </div>
                <div className='textbox'>
                  <h4>Third-party API Integrations</h4>
                  <p>
                    Integrated Twilio for timely notifications and reminders
                    about expiring credentials or application updates, and
                    Google Authenticator for enhanced security during login
                    workflows.
                  </p>
                  <div className='api-logos'>
                    <img
                      src='/caseStudies/newCaseStudies/ic_twilio.svg'
                      alt='Twilio API'
                      title='Twilio API'
                    />
                    <img
                      src='/caseStudies/newCaseStudies/ic_openAI.svg'
                      alt='openAI API'
                      title='openAI API'
                    />
                    <img
                      src='/caseStudies/newCaseStudies/ic_googleauthenticator.svg'
                      alt='Google Authenticator API'
                      title='Google Authenticator API'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='section-5'>
          <img
            src='/caseStudies/newCaseStudies/credenza-section5-cover.jpg'
            alt=''
          />
          <div className='textbox'>
            <div className='head'>
              <img src='/caseStudies/newCaseStudies/credenza-icon.svg' />
              <h3>
                Impact and <br /> Achievements
              </h3>
              <p>The implementation of solutions yielded impressive results</p>
            </div>
            <div className='listbox'>
              <div className='list-item'>
                <h5>Operational Efficiency</h5>
                <p>
                  Reduced the manual workload by 45% thus enabling doctors to
                  focus on patient care. Automated form filling saved users an
                  average of 3–5 hours per week.
                </p>
              </div>
              <div className='list-item'>
                <h5>Enhanced User Experience</h5>
                <p>
                  Doctors reported a 30% increase in satisfaction due to the
                  intuitive upload and AI-powered application management
                  features, while the platform&apos;s secure sharing options
                  reduced delays in credential verification by 25%.
                </p>
              </div>
              <div className='list-item'>
                <h5>Scalability and Security</h5>
                <p>
                  Successfully scaled to support 1,000+ doctors within the first
                  six months with no performance inconsistencies, while robust
                  HIPAA compliance increased user trust, with 95% of users
                  rating the platform as “highly secure.”
                </p>
              </div>
              <div className='list-item'>
                <h5>Financial Impact</h5>
                <p>
                  The time savings and efficiency improvements led to a 20%
                  increase in new user sign-ups, while API integrations
                  streamlined operational costs, reducing expenses by 25%.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id='section-6'>
          <div className='container'>
            <div className='head'>
              <h3>Credenza&apos;s user-friendly interface</h3>
              <p>
                Below are images showcasing Credenza&apos;s secure and
                user-friendly platform, designed for efficient credential
                management, automated form filling, and HIPAA-compliant secure
                sharing.
              </p>
            </div>
            <div className='portfolio'>
              <img
                src='/caseStudies/newCaseStudies/credenza-portfolio-1.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/credenza-portfolio-2.jpg'
                alt=''
              />
              <img
                src='/caseStudies/newCaseStudies/credenza-portfolio-3.jpg'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesCredenza;
